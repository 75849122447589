<template>
  <div>
    <!-- <div class="tableTitle clearfix" v-if="showChange == 1">
      <a class="longTextBtn finger btnReset fl marginRight18" @click="down">模板下载</a>
      <FormUploadFile label="模板导入"></FormUploadFile>
      <span class="fl longTextBtn finger btnReset" v-if="apiSrc">
        <FormUploadFile label="模板导入" :multiple="false" :apiSrc="apiSrc" @get-data="getUploadData"></FormUploadFile>
      </span>
    </div> -->
    <div class="subTitle">
      <span class="title"> 基础信息 </span>
    </div>
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="formLine">
        <FromLabel label="产品名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Select class="iviewIptWidth307" :disabled="Boolean(postFrom.is_approved)" placeholder="请输入/选择产品名称" filterable v-model="postFrom.name" ref="name" allow-create clearable @on-open-change="openChange" @on-create="handleCreate1" @on-change="selectProduct">
            <Option v-for="(item, index) in productName" :value="item.name" :key="index">{{ item.name }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="生产厂家" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <Select class="iviewIptWidth307" placeholder="请选择" filterable v-model="postFrom.factory_id" @on-change="selectFactory">
            <Option v-for="(item, index) in factoryList" :value="item.factory_id" :key="index">{{ item.factory_name }}</Option>
          </Select>
        </div>
        <FromLabel label="储运条件" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input placeholder="请输入" class="iviewIptWidth307" v-model.trim="postFrom.storage_condition"></i-input>
        </div>
      </div>
      <!-- <div class="formLine borderTopNone">
        <FromLabel label="最小包装规格" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
        
          <InputNumber :max="999999" :min="1" :precision="0" v-model="postFrom.unit.qty" :active-change="false" placeholder="请输入" class="iviewIptWidth100 marginRight18"></InputNumber>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.unit.unit" @on-change="changeUnitUnit" allow-create clearable ref="unitqty" @on-open-change="openChangeUnit($event, 'unit', 'qty', 'unitqty')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.unit.dose_unit" allow-create clearable ref="unitdose_unit" @on-open-change="openChangeUnit($event, 'unit', 'dose_unit', 'unitdose_unit')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
          <span class="spin">(单位可手动填)</span>
        </div>
        <FromLabel label="包装规格"></FromLabel>
        <div class="formFrIpt fl">
  
          <InputNumber :max="999999" :min="1" :precision="0" v-model="postFrom.packing_size.qty" :active-change="false" placeholder="请输入" class="iviewIptWidth100 marginRight18"></InputNumber>
          <i-input class="iviewIptWidth100" v-model="postFrom.unit.unit" disabled></i-input>
          <span class="xiegang">/</span>
          <Select class="iviewIptWidth100" placeholder="请选择" filterable v-model="postFrom.packing_size.dose_unit" allow-create clearable ref="packing_sizedose_unit" @on-open-change="openChangeUnit($event, 'packing_size', 'dose_unit', 'packing_sizedose_unit')" @on-create="handleCreateUnit">
            <Option v-for="(item, index) in unitList" :value="item" :key="index">{{ item }}</Option>
          </Select>
        </div>
      </div> -->
      <div class="formLine borderTopNone">
        <FromLabel label="资料维护" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <!--          <CheckboxGroup v-model="postFrom.qualification_maintain_type">-->
          <!--            <Checkbox label="1">注册证</Checkbox>-->
          <!--            <Checkbox label="2">备案凭证</Checkbox>-->
          <!--          </CheckboxGroup>-->
          <RadioGroup v-model="postFrom.qualification_maintain_type" @on-change="maintainTypeChange">
            <Radio label="1" style="margin-right: 40px">注册证</Radio>
            <Radio label="2">备案凭证</Radio>
          </RadioGroup>
        </div>
      </div>
    </div>
    <div class="tableTitle upload">
      <span class="title fl"> 产品实物图片 </span>
      <span class="fl btnUpload" v-if="showChange == 1">
        <FormUpload label="上传" @get-key="getKey" :multiple="true" @click="addFile" :httpUrl="httpUrl"></FormUpload>
      </span>
      <span class="paddingLef20">(需上传产品说明书，如果有条形码，需要上传条形码图片)</span>
    </div>
    <div class="imgBox">
      <ul>
        <li v-for="(item, index) in postFrom.image_urls" :key="index">
          <img :src="item" class="normalImg finger" @click="lookUp(item)" />
          <img src="../../../assets/images/deleteLogistics.png" @click="deleteImg(index)" class="deleteImg" />
          <div>({{ index + 1 }})</div>
        </li>
      </ul>
    </div>
    <!--    图片查看-->
    <lookup-image v-if="imgStatus && lookUpImg" @closeImg="imgStatus = false" title="图片资料" :imgUrl="lookUpImg"></lookup-image>

    <!-- 弹窗 -->
    <Modal v-model="pullproduct" width="1000" title="请选择需要同步的产品（如新增的的产品不在列表上，请点击新增产品）" @on-cancel="pullproduct = false">
      <Table :productList="listColumns" :productData="listData" :pages="newpages" :total="newtotal" totalText="条记录" :isLoad="isLoad" @change-page="changePage"></Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure marginLeft20" @click="pullproduct = false">新增产品</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
// import FormNewUpload from '@/components/newformUpload'
import LookupImage from '@/components/lookupImage'
import FormUploadFile from '@/components/formUploadFile'
import http from '@/service/baseUrl'
import { mapState } from 'vuex'
export default {
  name: 'productBaseInfo',
  components: {
    FormUpload,
    FromLabel,
    LookupImage,
    FormUploadFile,
    Table
    // FormUploadFile: () => import('@/components/formUploadFile')
  },
  computed: {
    ...mapState(['productInfo'])
  },
  data() {
    return {
      selectName:'',
      httpUrl:'/public/upload/file',
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minwidth: 100
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minwidth: 250
        },
        {
          title: '注册证号/备案凭证',
          // key: 'licence_code',
          align: 'center',
          minwidth: 100,
          render: (h, param) => {
            if (param.row.licence_code) {
              return h('div', [
                h(
                  'span',
                  {
                    // style: {
                    //   color: '#389AFC',
                    //   fontSize: '14px',
                    //   cursor: 'pointer'
                    // },
                    class: {
                      tableBlakcFont: true
                    }
                  },
                  param.row.licence_code
                )
              ])
            } else if (param.row.record_code) {
              return h('div', [
                h(
                  'span',
                  {
                    // style: {
                    //   color: '#389AFC',
                    //   fontSize: '14px',
                    //   cursor: 'pointer'
                    // },
                    class: {
                      tableBlakcFont: true
                    }
                  },
                  param.row.record_code
                )
              ])
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  // style: {
                  //   color: '#389AFC',
                  //   fontSize: '14px',
                  //   cursor: 'pointer'
                  // },
                  class: {
                    tableFont: true
                  },
                  on: {
                    click: () => {
                      this.queryInfo(param.row.factory_id, param.row.name,param.row)
                    }
                  }
                },
                '同步'
              )
            ])
          }
        }
      ],
      listData: [{ status_str: 123 }],
      pullproduct: false,
      total: 0,
      newpages: {
        page: 1,
        rows: 10
      },
      newtotal: 0,
      pages: {
        page: 1,
        rows: 10
      },
      isLoad: false,
      apiSrc: '',
      imgStatus: false,
      lookUpImg: '',
      oldProductList: [],
      productName: [],
      productBaseInfo: {},
      type: '',
      license: [],
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      factoryList: [],
      postFrom: {
        name: '',
        factory_id: '',
        packing_size: {
          qty: '',
          unit: '',
          dose_unit: ''
        },
        unit: {
          qty: '',
          unit: '',
          dose_unit: ''
        },
        storage_condition: '',
        image_urls: [],
        image_files: [],
        image_file_name: [],
        qualification_maintain_type: '',
        is_approved: null
      },
      showChange: 1,
      unitList: []
    }
  },
  created() {
    this.showChange = localStorage.getItem('showChange') || 2
    this.apiSrc = http.http + this.$api.productTemplateImport.url
  },
  mounted() {
    this.getProductName()
    this.getSearchProductFactory()
    this.$root.$on('productInfo', res => {
      let info = res
      if (info.name) {
        this.postFrom.name = info.name
        this.postFrom.factory_id = info.factory_id
        this.postFrom.packing_size.qty = info.packing_size.qty ? info.packing_size.qty : null
        this.postFrom.unit.qty = info.unit.qty ? info.unit.qty : null
        this.postFrom.storage_condition = info.storage_condition
        this.postFrom.image_urls = info.image_file_url ? info.image_file_url.split(',') : []
        this.postFrom.image_file_name = info.image_files_name ? info.image_files_name.split(',') : []
        this.postFrom.image_files = info.image_files ? info.image_files.split(',') : []
        this.postFrom.qualification_maintain_type = info.qualification_maintain_type + ''
        this.postFrom.is_approved = info.is_approved
        let arr = []
        if (this.postFrom.unit.unit || this.postFrom.unit.dose_unit || this.postFrom.packing_size.dose_unit) {
          arr = new Set([this.postFrom.unit.unit, this.postFrom.unit.dose_unit, this.postFrom.packing_size.dose_unit])
        }
        this.unitList = this.unitList.length > 0 ? [...new Set([...this.unitList, ...arr])] : [...new Set([...arr])]
      }
    })
    this.getUnit()
  },
  methods: {
    sortChange() {},
    changePage(e) {
      this.newpages.page = e
      let data = {
        name: this.selectName,
        page: this.newpages.page,
        rows: this.newpages.rows
      }
      this.$http.get(this.$api.productFactorySynchro, data, true).then(res => {
        this.listData = res.data.result
        this.newtotal = res.data.total
      })
    },
    maintainTypeChange(e) {
      this.$root.$emit('changeType', e)
    },
    changeIpt1(e, num) {
      let count = 0
      if (e.target.value * 1 < 1) {
        count = 1
      } else {
        count = e.target.value.trim() * 1
      }
      if (num == 1) {
        this.postFrom.unit.qty = count
      } else {
        this.postFrom.packing_size.qty = count
      }
    },
    changeUnitUnit(val) {
      if (val) {
        this.postFrom.packing_size.unit = val
      } else {
        this.postFrom.packing_size.unit = ''
      }
    },
    getUnit() {
      this.$http.get(this.$api.productUnit, null, false).then(res => {
        res.data.forEach(item => {
          this.unitList.push(item.unit)
        })
        if (this.unitList.length > 0) {
          this.unitList = [...new Set([...this.unitList])]
        }
        // let arr = []
        // if (this.postFrom.unit.unit || this.postFrom.unit.dose_unit || this.postFrom.packing_size.dose_unit) {
        //   arr = new Set([this.postFrom.unit.unit, this.postFrom.unit.dose_unit, this.postFrom.packing_size.dose_unit])
        // }
        // this.unitList = this.unitList.length > 0 ? [...new Set([...this.unitList, ...arr])] : [...new Set([...arr])]
        // this.unitList = this.unitList.length > 0 ? JSON.parse(JSON.stringify(this.unitList)) : []
        // console.log(this.unitList)
      })
    },
    down() {
      this.$http.downFile(this.$api.productTemplateDownload, null, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '产品导入模版' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          // eslint-disable-next-line camelcase
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    openChange(status) {
      if (!status && !this.postFrom.name) {
        this.$refs.name.query = ''
      }
    },
    openChangeUnit(status, obj, name, refName) {
      if (!status && !this.postFrom[obj][name]) {
        this.$refs[refName].query = ''
      }
    },
    handleCreateUnit(val) {
      this.unitList.push(val.trim())
    },
    // 模版导入
    getUploadData(data) {
      let obj = {
        name: data.product_info.name
      }
      if (!this.productName.includes(obj)) {
        this.productName.push({
          name: data.product_info.name
        })
      }
      this.postFrom = data.product_info
      if (!this.postFrom.packing_size) {
        this.postFrom.packing_size = {
          qty: '',
          unit: '',
          dose_unit: ''
        }
        this.postFrom.unit = {
          qty: '',
          unit: '',
          dose_unit: ''
        }
      }
      if (!this.postFrom.unit) {
      }
      this.postFrom.image_urls = data.image_file_url ? data.image_file_url.split(',') : []
      this.postFrom.image_file_name = data.image_files_name ? data.image_files_name.split(',') : []
      this.postFrom.image_files = data.image_files ? data.image_files.split(',') : []
      this.postFrom.qualification_maintain_type = this.postFrom.qualification_maintain_type + ''
      if (data.product_info.qualification_maintain_type == '1') {
        this.$root.$emit('productInfo', data.product_licences)
      } else if (data.qualification_maintain_type == '2') {
        this.$root.$emit('productInfo', data.product_record)
      }
    },
    lookUp(item) {
      this.lookUpImg = item
      this.imgStatus = true
    },
    getKey(key, name, url) {
      // this.$set(this.postFrom.image_urls, this.postFrom.image_urls.length, url)
      this.postFrom.image_urls.push(url)
      this.postFrom.image_files.push(key)
      this.postFrom.image_file_name.push(name)
      this.postFrom = JSON.parse(JSON.stringify(this.postFrom))
      console.log(this.postFrom)
    },
    // 选择商品带出详情信息，新建的商品清楚原有数据
    selectProduct(e) {
      if (!e) {
        return
      }else {
        this.selectName = e
      }
      this.pullproduct = true
      let data = {
        name: e,
        page: this.newpages.page,
        rows: this.newpages.rows
      }
      this.$http.get(this.$api.productFactorySynchro, data, true).then(res => {
        this.listData = res.data.result
        this.newtotal = res.data.total
      })
      // if (e && this.postFrom.factory_id) {
      //   this.queryInfo(this.postFrom.factory_id, e)
      // }
    },
    // 工厂更改
    selectFactory(e) {
      // if (e && this.postFrom.name) {
      //   this.queryInfo(e, this.postFrom.name)
      // }
    },
    // 选择商品带出详情信息，新建的商品清楚原有数据
    queryInfo(factoryId, name , certificateCode) {
      this.postFrom.factory_id = factoryId
      let twoCode = certificateCode.record_code?certificateCode.record_code:certificateCode.licence_code
      this.$http.get(this.$api.productSearchProductMessage, { name: name, factory_id: factoryId,certificate_code: twoCode}, true).then(res => {
        if (res.data.product_info) {
          let info = res.data.product_info
          this.postFrom = Object.assign({}, res.data.product_info, this.postFrom)
          this.postFrom.packing_size = info.packing_size || { qty: '', unit: '', dose_unit: '' }
          this.postFrom.unit = info.unit || { qty: '', unit: '', dose_unit: '' }
          this.postFrom.storage_condition = info.storage_condition
          this.postFrom.image_urls = info.image_file_url
          this.postFrom.image_file_name = info.image_files_name ? info.image_files_name.split(',') : []
          this.postFrom.image_files = info.image_files ? info.image_files.split(',') : []
          this.postFrom.qualification_maintain_type = info.qualification_maintain_type + ''
          this.postFrom.product_id = null
          this.$parent.next(1)
          if (info.qualification_maintain_type == '1') {
            res.data.product_licences.licence_product_code = res.data.product_licences.product_code
            res.data.product_licences.flag = true
            this.$root.$emit('productInfo', res.data.product_licences)
          } else if (info.qualification_maintain_type == '2') {
            res.data.product_licences.flag = true
            res.data.product_record.record_product_code = res.data.product_record.product_code
            this.$root.$emit('productInfo', res.data.product_record)
          }
        } else {
          this.postFrom.product_id = null
          this.postFrom.packing_size = { qty: '', unit: '', dose_unit: '' }
          this.postFrom.unit = { qty: '', unit: '', dose_unit: '' }
          this.postFrom.storage_condition = ''
          this.postFrom.image_files = []
          this.postFrom.image_urls = []
          this.postFrom.image_file_name = []
          this.postFrom.qualification_maintain_type = ''
          this.$parent.noProduct()
        }
        this.pullproduct = false
      })
    },
    // 新增产品
    handleCreate1(val) {
      this.productName.push({
        name: val.trim()
      })
    },
    // 产品厂家
    getSearchProductFactory() {
      this.$http.get(this.$api.searchProductFactory).then(res => {
        this.factoryList = res.data
      })
    },
    // 产品名称
    getProductName() {
      this.$http
        .get(this.$api.searchProduct)
        .then(res => {
          return res.data
        })
        .then(list => {
          let nameList = list.map(item => item.name)
          if (this.productInfo.name) {
            if (nameList.indexOf(this.productInfo.name) == -1) {
              list.push({
                name: this.productInfo.name
              })
            }
          }
          this.productName = list ? JSON.parse(JSON.stringify(list)) : []
          this.oldProductList = list ? JSON.parse(JSON.stringify(list)) : []
          if (this.productInfo) {
            let info = this.productInfo
            this.postFrom.name = info.name
            this.postFrom.factory_id = info.factory_id
            this.postFrom.packing_size = info.packing_size || { qty: '', unit: '', dose_unit: '' }
            this.postFrom.unit = info.unit || { qty: '', unit: '', dose_unit: '' }
            this.postFrom.storage_condition = info.storage_condition
            this.postFrom.image_urls = info.image_file_url ? info.image_file_url.split(',') : []
            this.postFrom.image_file_name = info.image_files_name ? info.image_files_name.split(',') : []
            this.postFrom.image_files = info.image_files ? info.image_files.split(',') : []
            this.postFrom.qualification_maintain_type = info.qualification_maintain_type + ''
          }
        })
    },
    addFile() {},
    deleteImg(i) {
      this.postFrom.image_urls.splice(i, 1)
      this.postFrom.image_files.splice(i, 1)
    },
    addType() {
      this.typeList.push(2)
    },
    removeType() {
      if (this.typeList.length === 1) return
      this.typeList.pop()
    }
  }
}
</script>

<style scoped lang="less">
.paddingLef20 {
  padding-left: 20px;
  font-size: 16px;
  color: red;
}
.marginRight18 {
  margin-right: 18px;
}
.xiegang {
  margin: 0 18px;
  color: #ccc;
}
.btnUpload {
  margin-left: 20px;
  display: inline-block;
  width: 76px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
  background: #f0f8ff;
  color: #389afc;
  border: 1px solid #389afc;
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.upload {
  height: 36px;
  line-height: 36px;
}
.subTitle {
  margin: 20px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }
}
.tableTitle {
  margin: 17px 0 0px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  margin-top: 20px;
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      div {
        width: 171px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        color: #000;
      }
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.iviewIptWidth100 {
  width: 100px;
  height: 36px;
  line-height: 36px;
}
/deep/ .ivu-input-number-handler-up {
  margin-top: 2px;
}
.spin {
  margin-left: 20px;
  font-size: 14px;
  color: #525b6d;
}
</style>
